<template>
  <component
    :is="tag"
    v-if="relatedFacilities.length"
    class="related-facilities"
  >
    <div class="section-inner">
      <h2 v-if="blok.title" class="size-xl align-center margin-s-bottom">{{ blok.title }}</h2>
      <div
        v-if="
          isDesktop &&
          relatedFacilities.length > 0 &&
          relatedFacilities.length < 4
        "
        class="facility-cards"
      >
        <StoryblokComponent
          v-for="relatedFacility in relatedFacilities"
          :key="relatedFacility._uid"
          :blok="relatedFacility"
          :level="3"
          class="card"
          :size-hint="45"
        />
      </div>
      <div
        v-if="!isDesktop || relatedFacilities.length > 3"
        class="carousel-container"
      >
        <GenericCarousel
          v-if="relatedFacilities.length > 1"
          :items="relatedFacilities"
          :slides-per-view="'auto'"
          :space-between="14"
          class="carousel-navigation-bottom"
          :child-level="3"
          child-classes="card"
          :size-hint="45"
        />
      </div>
    </div>
  </component>
</template>

<script setup>
const props = defineProps({
  blok: {
    type: Object,
    required: true,
  },
  context: {
    type: Object,
    required: false,
    default: () => ({}),
  },
  tag: {
    type: String,
    required: false,
    default: "div",
  },
});

const { blok, context } = toRefs(props); // eslint-disable-line
const mergedData = computed(() => ({ ...context.value, ...blok.value }));
const relatedFacilities = mergedData.value?.interestedIn?.length
  ? useFacilitiesMediaTextCta(mergedData.value?.interestedIn)
  : [];

const isDesktop = inject("isDesktop");
</script>

<style lang="scss" scoped>
.alternate {
  .related-facilities {
    @include pair-1;
    :deep(.swiper-button-prev),
    :deep(.swiper-button-next) {
      &::before {
        background-color: var(--solid-01);
      }
    }
  }
}

.facility {
  &-cards {
    display: flex;
    align-items: center;
    gap: 0.83rem;
  }
}
</style>
